<template>
  <div class="page">
    <el-form size="small"
             :inline="true"
             class="query-form"
             style="    display: none;"
             ref="searchForm"
             @keyup.enter.native="refreshList()"
             @submit.native.prevent>
      <el-form-item prop="type">
        <el-select v-model="exhibitionValue"
                   filterable
                   placeholder="请选择展览"
                   @change="selectExhibition()">
          <el-option v-for="item in exhibition"
                     :key="item.id"
                     :label="item.exhibitionName"
                     :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="top bg-white"
         style="height: calc(100%);"
         id="reorganize">
      <el-container style="height:100%">
        <el-header height="120px">
          <el-steps :active="active"
                    align-center
                    style="margin: 20px 200px 20px;"
                    finish-status="success">
            <el-step title="文件检测"></el-step>
            <el-step title="文件编辑"></el-step>
            <el-step title="申报书生成"></el-step>
            <el-step title="生成完成"></el-step>
          </el-steps>
        </el-header>
        <el-main v-loading="mainLoading"
                 :element-loading-text="mainLoadingText">
          <el-tabs v-model="activeName">
            <el-tab-pane label="文件检测"
                         name="文件检测">
              <el-tabs v-model="activeMain">
                <el-tab-pane name="first">
                  <el-result subTitle="请选择展览">
                    <template slot="extra">
                      <el-form :inline="true"
                               class="query-form"
                               ref="searchForm"
                               @keyup.enter.native="refreshList()"
                               @submit.native.prevent>
                        <el-form-item prop="type">
                          <el-select v-model="exhibitionValue"
                                     filterable
                                     placeholder="请选择展览"
                                     @change="selectExhibition()">
                            <el-option v-for="item in exhibition"
                                       :key="item.id"
                                       :label="item.exhibitionName"
                                       :value="item.id">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                    </template>
                  </el-result>

                </el-tab-pane>
                <el-tab-pane name="second">
                  <el-result :icon="icon"
                             :title="title"
                             :subTitle="subTitle"><template slot="icon">
                    </template>
                    <template slot="extra">
                      <el-button type="primary"
                                 @click="goBack">返回上一级</el-button>
                      <el-button type="primary"
                                 v-if="verifyButtonShow"
                                 @click="next">继续下一步</el-button>
                    </template>
                  </el-result>
                </el-tab-pane>
              </el-tabs>

            </el-tab-pane>
            <el-tab-pane label="文件预览"
                         name="文件预览">
              <div>
                <vue-tinymce ref="createTinymce"
                             v-model="content"
                             :setup="setup"
                             :setting="setting" />
              </div>

            </el-tab-pane>
            <el-tab-pane label="报价单生成"
                         name="报价单生成">
            </el-tab-pane>
            <el-tab-pane label="生成完成"
                         name="生成完成">
              <el-result icon="success"
                         title="生成成功"
                         >
                <template slot="extra">
                  <el-button type="primary"
                             @click="wordDownload">word版本下载</el-button>
                  <el-button type="primary"
                             @click="mimeograph">打印</el-button>
                  <el-button type="primary"
                             @click="pdfDownload">PDF版本下载</el-button>
                  <el-button type="primary"
                             @click="preview">预览</el-button>
                  <el-button type="primary"
                             @click="goBack">重新选择展览</el-button>
                </template>
              </el-result>

            </el-tab-pane>
          </el-tabs>
        </el-main>
        <el-footer>
          <el-button style="margin-top: 12px;    float: right;margin-left: 10px;"
                     v-if="nextButtonShow"
                     @click="next">下一步</el-button>
          <el-button style="margin-top: 12px;    float: right;margin-left: 10px;"
                     v-if="nextButtonShow"
                     @click="goBack">重新选择展览</el-button>

        </el-footer>
      </el-container>

    </div>
    <el-dialog title="预览"
               class="preview-div"
               :visible.sync="dialogVisible"
               width="595px"
               :before-close="handleClose">
      <div v-html='content'></div>
    </el-dialog>
  </div>
</template>

<script>
import {
  declarationFormTemplateListApi,
  declarationFormTemplatePreviewApi,
  declarationFormTemplateDocumentInspection,
  downloadFileWithBuffer,
  crmFileDownByPathAPI,
} from '@/api/declaration/declarationFormTemplate'
export default {
  data() {
    return {
      searchStr: '',
      title: '',
      subTitle: '',
      icon: '',
      dataList: [],
      pageNo: 1,
      pageSize: 10,
      activeMain: 'first',
      total: 0,
      dialogVisible: false,
      dataListSelections: [],
      richHeight: document.documentElement.clientHeight - 135,
      toolbar:
        'fontselect fontsizeselect link lineheight forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | image quicklink h2 h3 blockquote table numlist bullist preview fullscreen | importword', //工具栏
      dictTypeTitle: '',
      rightVisible: false,
      loading: false,
      exhibition: [],
      exhibitionValue: '',
      releaseId: '',
      active: 0,
      activeName: '文件检测',
      mainLoading: false,
      mainLoadingText: '',
      nextButtonShow: false,
      verifyButtonShow: false,
      content: '',
      setting: this.getEditConfig(),
    }
  },
  created() {
    this.refreshList()
    this.exhibitionList()
  },
  computed: {
    editor() {
      return this.$refs.createTinymce.editor
    },
  },
  watch: {
    active(curVal, oldVal) {
      if (this.active >= 3) {
        // 按钮隐藏
        this.nextButtonShow = false
      }
      if (this.active == 0) {
        this.activeName = '文件检测'
      } else if (this.active == 1) {
        this.activeName = '文件预览'
      } else if (this.active == 2) {
        this.activeName = '报价单生成'
        this.mainLoading = true
        this.mainLoadingText = '系统正在生成申报书'
        this.nextButtonShow = false
        this.timer = setTimeout(() => {
          //设置延迟执行
          this.mainLoading = false

          this.next()
        }, 1000)
      } else if (this.active == 3) {
        this.activeName = '生成完成'
      }
    },
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    init() {
      this.mainLoading = true
      this.mainLoadingText = '系统正在进行表单完整性检测、文件完整性检测'
    },
    goBack() {
      this.exhibitionValue = ""
      this.activeMain = 'first'
      this.active = 0
      this.nextButtonShow = false
    },
    next() {
      this.active++
    } /** 富文本配置 */,
    getEditConfig() {
      return {
        menubar: false,
        toolbar_sticky: true,
        statusbar: false,
        toolbar:
          'undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | table | fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat | pagebreak |',
        toolbar_drawer: 'sliding',
        quickbars_selection_toolbar:
          'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
        quickbars_insert_toolbar: false,
        plugins: 'link image media table lists fullscreen quickbars pagebreak',
        language: 'zh_CN',
        language_url:
          './static/tinymce/langs/zh_CN.js',
        height: document.documentElement.clientHeight - 400,
        // extended_valid_elements: 'span[class|title|wktag|style|contenteditable]',
        content_style: 'p { margin: 5px 0; line-height: 1.5;}',
        content_css: [
          './static/tinymce/css/tiny-wk-colors.css',
          './static/tinymce/css/tiny-wk-word.css',
        ],
        table_advtab: false,
        table_cell_advtab: false,
        table_row_advtab: false,
        setup: (editor) => {
          editor.on('init', function (e) {
            editor.execCommand('mceFocus')
          })
          editor.on('keydown', (e) => {
            this.selectNodes = null
            if (
              e.keyCode === 8 /* Backspace key */ ||
              /* del key */ e.keyCode == 46
            ) {
              const currentNode = editor.selection.getNode()
              if (
                currentNode.getAttribute('data-wk-table-td-tag') === 'value'
              ) {
                e.preventDefault()
                return false
              } else if (currentNode.nodeName == 'SPAN') {
                if (currentNode.hasAttribute('contenteditable')) {
                  e.preventDefault()
                  editor.dom.remove(currentNode)
                  return false
                } else {
                  const farterSpan = this.getCurrentParentByTag(
                    'span[contenteditable]'
                  )
                  if (farterSpan) {
                    e.preventDefault()
                    editor.dom.remove(farterSpan)
                    return false
                  }
                }
              }
            } else if (e.keyCode == '65' && (e.metaKey || e.ctrlKey)) {
              // 全选
              this.selectNodes = editor.selection.getNode()
              this.setSpanEditAttr(this.selectNodes, true)
            } else {
              // table 里面不允许写内容
              const currentNode = editor.selection.getNode()
              if (
                currentNode.getAttribute('data-wk-table-td-tag') === 'value'
              ) {
                // 只允许输入上下左右
                if (
                  e.keyCode !== 37 &&
                  e.keyCode !== 38 &&
                  e.keyCode !== 39 &&
                  e.keyCode !== 40
                ) {
                  e.preventDefault()
                  return false
                }
              } else if (currentNode.nodeName == 'SPAN') {
                if (currentNode.hasAttribute('contenteditable')) {
                  if (currentNode.getAttribute('contenteditable')) {
                    e.preventDefault()
                    currentNode.setAttribute('contenteditable', false)
                    return false
                  }
                  return false
                } else {
                  const farterSpan = this.getCurrentParentByTag(
                    'span[contenteditable]'
                  )
                  if (farterSpan) {
                    if (farterSpan.getAttribute('contenteditable')) {
                      e.preventDefault()
                      farterSpan.setAttribute('contenteditable', false)
                      return false
                    }
                    return false
                  }
                }
              }

              if (
                e.keyCode == 37 &&
                e.keyCode == 38 &&
                e.keyCode == 39 &&
                e.keyCode == 40
              ) {
                // console.log('上下左右')
                this.cancelSpanEdit()
              }
            }
          })

          // editor.on('focusin', function(e) {
          //   console.log('focusin')
          // })

          // editor.on('focusout', function(e) {
          //   console.log('focusout')
          // })

          // editor.on('click', function(e) {
          //   console.log('click')
          // })

          // editor.on('blur', (e) => {
          //   console.log('blur')
          // })

          editor.on('mousedown', (e) => {
            this.cancelSpanEdit()
          })

          editor.on('mouseup', (e) => {
            const selection = editor.selection.getSel()
            // console.log(selection.isCollapsed, selection.anchorNode == selection.focusNode, selection.anchorOffset == selection.focusOffset)

            if (
              e.target.hasAttribute('contenteditable') &&
              selection.anchorOffset == 1 &&
              selection.anchorOffset == 1
            ) {
              // 忽略
            } else if (
              e.target.hasAttribute('contenteditable') &&
              selection.isCollapsed
            ) {
              this.cancelSpanEdit()
            } else {
              if (!selection.isCollapsed) {
                this.selectNodes = editor.selection.getNode()
                this.setSpanEditAttr(this.selectNodes, true)
              } else {
                this.cancelSpanEdit()
              }
            }
          })
        },
      }
    },
    /**
     * 取消编辑
     */ cancelSpanEdit() {
      if (this.selectNodes) {
        setTimeout(() => {
          const selection = this.editor.selection.getSel()
          if (!selection.isCollapsed) {
            this.setSpanEditAttr(this.selectNodes, false)
            this.selectNodes = null
          }
        }, 300)
      }
    },
    setup(editor) {
      editor.on('init', function (e) {
        editor.execCommand('mceFocus')
      })
      editor.on('keydown', (e) => {
        this.selectNodes = null
        if (
          e.keyCode === 8 /* Backspace key */ ||
          /* del key */ e.keyCode == 46
        ) {
          const currentNode = editor.selection.getNode()
          if (currentNode.getAttribute('data-wk-table-td-tag') === 'value') {
            e.preventDefault()
            return false
          } else if (currentNode.nodeName == 'SPAN') {
            if (currentNode.hasAttribute('contenteditable')) {
              e.preventDefault()
              editor.dom.remove(currentNode)
              return false
            } else {
              const farterSpan = this.getCurrentParentByTag(
                'span[contenteditable]'
              )
              if (farterSpan) {
                e.preventDefault()
                editor.dom.remove(farterSpan)
                return false
              }
            }
          }
        } else if (e.keyCode == '65' && (e.metaKey || e.ctrlKey)) {
          // 全选
          this.selectNodes = editor.selection.getNode()
          this.setSpanEditAttr(this.selectNodes, true)
        } else {
          // table 里面不允许写内容
          const currentNode = editor.selection.getNode()
          if (currentNode.getAttribute('data-wk-table-td-tag') === 'value') {
            // 只允许输入上下左右
            if (
              e.keyCode !== 37 &&
              e.keyCode !== 38 &&
              e.keyCode !== 39 &&
              e.keyCode !== 40
            ) {
              e.preventDefault()
              return false
            }
          } else if (currentNode.nodeName == 'SPAN') {
            if (currentNode.hasAttribute('contenteditable')) {
              if (currentNode.getAttribute('contenteditable')) {
                e.preventDefault()
                currentNode.setAttribute('contenteditable', false)
                return false
              }
              return false
            } else {
              const farterSpan = this.getCurrentParentByTag(
                'span[contenteditable]'
              )
              if (farterSpan) {
                if (farterSpan.getAttribute('contenteditable')) {
                  e.preventDefault()
                  farterSpan.setAttribute('contenteditable', false)
                  return false
                }
                return false
              }
            }
          }

          if (
            e.keyCode == 37 &&
            e.keyCode == 38 &&
            e.keyCode == 39 &&
            e.keyCode == 40
          ) {
            // console.log('上下左右')
            this.cancelSpanEdit()
          }
        }
      })

      // editor.on('focusin', function(e) {
      //   console.log('focusin')
      // })

      // editor.on('focusout', function(e) {
      //   console.log('focusout')
      // })

      // editor.on('click', function(e) {
      //   console.log('click')
      // })

      // editor.on('blur', (e) => {
      //   console.log('blur')
      // })

      editor.on('mousedown', (e) => {
        this.cancelSpanEdit()
      })

      editor.on('mouseup', (e) => {
        const selection = editor.selection.getSel()
        // console.log(selection.isCollapsed, selection.anchorNode == selection.focusNode, selection.anchorOffset == selection.focusOffset)

        if (
          e.target.hasAttribute('contenteditable') &&
          selection.anchorOffset == 1 &&
          selection.anchorOffset == 1
        ) {
          // 忽略
        } else if (
          e.target.hasAttribute('contenteditable') &&
          selection.isCollapsed
        ) {
          this.cancelSpanEdit()
        } else {
          if (!selection.isCollapsed) {
            this.selectNodes = editor.selection.getNode()
            this.setSpanEditAttr(this.selectNodes, true)
          } else {
            this.cancelSpanEdit()
          }
        }
      })
    },
    // 获取数据列表
    refreshList() {
      this.loading = true
      var params = {
        current: this.pageNo,
        size: this.pageSize,
        searchStr: this.searchStr,
      }
      declarationFormTemplateListApi(params).then((res) => {
        if (res && res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          this.loading = false
        }
      })
    },
    exhibitionList() {
      this.$axios(
        this.api.infor.informationList,
        {
          exhibitionName: '',
          pageNo: 1,
          pageSize: 9999,
        },
        'get'
      ).then((data) => {
        if (data && data.status) {
          this.exhibition = data.data.records
        }
      })
    },
    // 新建、编辑
    view(method, row) {
      this.$refs.declarationFormTemplateForm.init(method, row)
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    download(row) {
      declarationFormTemplatePreviewApi(row.content)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: '',
          })
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '文档.doc' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        })
        .catch(() => {})
    },
    downloadArticle(row) {
      var FileSaver = require('file-saver')
      var html = '这里是你的html内容'
      //var html = "<html>" + this.$refs.article.innerHTML + "</html>";
      var param = new URLSearchParams()
      param.append('html', html)
      const _static = {
        mhtml: {
          top:
            'Mime-Version: 1.0\nContent-Base: ' +
            location.href +
            '\nContent-Type: Multipart/related; boundary="NEXT.ITEM-BOUNDARY";type="text/html"\n\n--NEXT.ITEM-BOUNDARY\nContent-Type: text/html; charset="utf-8"\nContent-Location: ' +
            location.href +
            '\n\n<!DOCTYPE html>\n<html>\n_html_</html>',
          head: '<head>\n<meta http-equiv="Content-Type" content="text/html; charset=utf-8">\n<style>\n_styles_\n</style>\n</head>\n',
          body: '<body>_body_</body>',
        },
      }
      const dealhtml = row.content
      let mhtmlBottom = '\n'

      //mhtmlBottom += '--NEXT.ITEM-BOUNDARY--'
      // 整合html代码片段
      const fileContent =
        _static.mhtml.top.replace(
          '_html_',
          _static.mhtml.body.replace('_body_', dealhtml)
        ) + mhtmlBottom
      console.log(fileContent)
      // 导出
      const blob = new Blob([fileContent], {
        type: 'application/msword;charset=utf-8',
      })
      saveAs(blob, `testImage.doc`)
    },

    // 删除
    del(id) {
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        declarationFormTemplateDeleteApi(id).then((res) => {
          if (res && res.status) {
            console.log(res)
            this.$message.success(res.data)
            this.refreshList()
            this.$dictUtils.refreshDictList()
          }
        })
      })
    },
    /**
     * 重置搜索
     */
    resetSearch() {
      this.searchStr = ''
      this.refreshList()
    },
    selectExhibition() {
      this.active = 0
      this.mainLoading = true
      this.nextButtonShow = false
      this.verifyButtonShow = false
      this.mainLoadingText = '系统正在进行表单完整性检测、文件完整性检测'
      declarationFormTemplateDocumentInspection(this.exhibitionValue).then(
        (res) => {
          // 检验数据
          if (res.code === 9000 || res.code === 9001) {
            // 如果数据完成性不通过,则提示不允许生成
            this.activeMain = 'second'
            this.mainLoading = false
            this.title = '检验失败'
            this.subTitle = res.msg
          } else if (res.code === 9003) {
            // 如果是资料完整性不通过,则提示判断
            this.verifyButtonShow = true
            this.activeMain = 'second'
            this.mainLoading = false
            this.title = '检验失败'
            this.subTitle = res.msg
          } else {
            // 否则通过
            this.timer = setTimeout(() => {
              //设置延迟执行
              this.mainLoading = false
              this.content = res.data.content
              this.nextButtonShow = true
              this.next()
            }, 1000)
          }
        }
      )
    },
    /**
     * word下载
     */
    wordDownload() {
      declarationFormTemplatePreviewApi(this.content, 'word')
        .then((res) => {
          const blob = new Blob([res.data], {
            type: '',
          })
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '文档.doc' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        })
        .catch(() => {})
    },
    /**
     * PDF下载
     */
    pdfDownload() {
      declarationFormTemplatePreviewApi(this.content, 'pdf')
        .then((res) => {
          const blob = new Blob([res.data], {
            type: '',
          })
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '文档.pdf' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        })
        .catch(() => {})
    },
    /**
     * 预览
     */
    preview() {
      this.dialogVisible = true
    },
    /**
     * 打印
     */
    mimeograph() {
      declarationFormTemplatePreviewApi(this.content, 'pdf')
        .then((res) => {
          const blob = new Blob([res.data], {
            type: 'application/pdf;chartset=UTF-8',
          })
          let fileURL = URL.createObjectURL(blob)

          // 下载代码
          // let downEle = document.createElement('a')
          // let fname = `download` //下载文件的名字
          // downEle.href = fileURL
          // downEle.setAttribute('download', fname)
          // document.body.appendChild(downEle)
          // downEle.click()
          window.open(fileURL)
        })
        .catch(() => {})
    },
  },
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
::v-deep .is-top {
  display: none !important;
}

.preview-div {
  ::v-deep .MsoNormalTable {
    margin: 0 auto !important;
    border: 1px solid #000000 !important;
  }

  ::v-deep tr td {
    border: 1px solid #000000 !important;
  }
}
</style>
